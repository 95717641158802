<template>
  <div>
    <v-layout column wrap>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h5 class="font-weight-light">LIST OF BUSINESS BRANCH</h5>
          </v-toolbar-title>
        </v-toolbar>
        <v-data-table dense
                      :headers="headers"
                      :items="data_items"
                      :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Data</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                class="mx-2"
                :append-icon="icons.mdiAccountSearch"
                label="Search"
                single-line
                hide-details
                v-model="search"
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.id }}
              </td>
              <td>
                {{ item.funeral_category }}
              </td>
              <td>
                {{ item.address }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.contact_no }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
  import {
    mdiAccountGroup
    , mdiAccountSearch
  } from '@mdi/js'
  import {mapActions, mapGetters} from "vuex";

  export default {
    props: {
      data_items: Array,
    },
    data() {
      return {
        icons: {
          mdiAccountGroup,
          mdiAccountSearch,
        },

        search: '',
        headers: [
          {text: 'ID', value: 'id', sortable: true},
          {text: 'Funeral Category', value: 'funeral_category', sortable: true},
          {text: 'Address', value: 'address', sortable: true},
          {text: 'Contact Person', value: 'name', sortable: true},
          {text: 'Contact #', value: 'contact_no', sortable: true},
        ],
      }
    },
  }
</script>
